// brand.action.js
import axios from "axios";
import { setToast } from "../../util/toast";
import * as ActionType from "./brand.type";
import { baseURL, key } from "../../util/config";

const axiosInstance = axios.create({
  baseURL,
  headers: {
    "Content-Type": "application/json",
    key: key,
    Authorization: `${localStorage.getItem("token")}`,
  },
});

export const fetchBrands = () => async (dispatch) => {
  try {
    const response = await axiosInstance.get(`/brand/getAllBrands`);
    dispatch({
      type: ActionType.GET_BRANDS,
      payload: response.data.brands,
    });
  } catch (error) {
    console.error("Error fetching brands:", error);
  }
};

export const createBrand = (name) => async (dispatch) => {
  try {
    const response = await axiosInstance.post(`/brand/create`, { name });
    dispatch({
      type: ActionType.CREATE_BRAND,
      payload: response.data,
    });
    setToast("success", "Brand created successfully!");
  } catch (error) {
    console.error("Error creating brand:", error);
    setToast("error", "Failed to create brand.");
  }
};

export const updateBrand = (id, name) => async (dispatch) => {
  try {
    const response = await axiosInstance.put(`/brand/update/${id}`, { name });
    dispatch({
      type: ActionType.UPDATE_BRAND,
      payload: response.data,
    });
    setToast("success", "Brand updated successfully!");
  } catch (error) {
    console.error("Error updating brand:", error);
    setToast("error", "Failed to update brand.");
  }
};

export const deleteBrand = (id) => async (dispatch) => {
  try {
    await axiosInstance.delete(`/brand/delete/${id}`);
    dispatch({
      type: ActionType.DELETE_BRAND,
      payload: id,
    });
    setToast("success", "Brand deleted successfully!");
  } catch (error) {
    console.error("Error deleting brand:", error);
    setToast("error", "Failed to delete brand.");
  }
};
